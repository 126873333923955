import { METHOD, request } from "./../../utils/request";
import { FollowUpSettings } from "./../api";

async function getAll(params) {
  return request(FollowUpSettings.getAll, METHOD.GET, params);
}
async function getDetails(params) {
  return request(FollowUpSettings.getDetails, METHOD.GET, params);
}
async function getAllUsers(params) {
  return request(FollowUpSettings.getAllUsers, METHOD.GET, params);
}
async function getAllEducationalCategories(params) {
  return request(
    FollowUpSettings.getAllEducationalCategories,
    METHOD.GET,
    params
  );
}

async function add(data) {
  return request(FollowUpSettings.add, METHOD.POST, data);
}
async function update(data) {
  return request(FollowUpSettings.update, METHOD.POST, data);
}

export default {
  getAll,
  getDetails,
  getAllUsers,
  getAllEducationalCategories,
  add,
  update,
};
