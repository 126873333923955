import BaseEntityModelFilter from "./../../general/BaseEntityModelFilter";
import store from "./../../../store";

export default class FollowUpSettingsFilter extends BaseEntityModelFilter {
  constructor() {
    super();
    this.setInitialValue();
  }
  setInitialValue() {
    this.setFilterInitialValue();
    this.activationTypeTokens =
      store.getters.generalSetting.followUpSettings.activationTypeTokens ?? [];

    this.userProfileDataInclude = true;
    this.userInterestStatisticDataInclude = true;
    this.userStatisticDataInclude = false;
    this.establishmentRoleDataInclude = false;
    this.geographicalDistributionToken = "";
    this.nationalityCountryToken = "";
    this.socialStatuseToken = "";
    this.insuranceCompanyToken = "";
    this.bankToken = "";
    this.religionToken = "";
    this.parentsSocialStatusToken = "";
    this.jobGradeToken = "";
    this.educationalStageToken = "";
    this.specialtieToken = "";
    this.userTypeToken = "";
    this.inFollowUpSettingsUserToken = "";
    this.notInFollowUpSettingsUserToken = "";
    this.systemComponentsHierarchyDataInclude = true;
    this.systemComponentDataInclude = true;
  }
  fillData(data) {
    if (data) {
      this.fillFilterData(data);
    } else {
      this.setInitialValue();
    }
  }
}
