import defaultImg from "@/assets/images/sales.svg";
import UserSalesInfo from "./UserSalesInfo";

export default class UserSalesFollowUpSetting {
  icon = defaultImg;
  defaultImg = defaultImg;
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.userSalesInfo = new UserSalesInfo();
    this.userSalesWithStudentsSettingsData = [];
    this.userSalesWithEducationalCategoriesSettingsData = [];
  }
  fillData(data) {
    if (data) {
      this.userSalesInfo.fillData(data.userSalesInfo);
      this.userSalesWithStudentsSettingsData =
        data.userSalesWithStudentsSettingsData ?? [];
      this.userSalesWithEducationalCategoriesSettingsData =
        data.userSalesWithEducationalCategoriesSettingsData ?? [];
    } else {
      this.setInitialValue();
    }
  }
}
