import defaultImg from "@/assets/images/followUpSettings.svg";
import { getUserAuthorizeToken } from "./../../../utils/functions";

export default class FollowUpSetting {
  icon = defaultImg;
  defaultImg = defaultImg;
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.userAuthorizeToken = getUserAuthorizeToken();
    this.userSalesToken = "";
    this.userStudentTokens = [];
    this.educationalCategoryTokens = [];
  }
  fillData(data) {
    if (data) {
      this.userSalesToken = data.userSalesToken ?? "";
      this.userStudentTokens = data.userStudentTokens ?? [];
      this.educationalCategoryTokens = data.educationalCategoryTokens ?? [];
    } else {
      this.setInitialValue();
    }
  }
}
