import BaseEntityModelFilter from "./../../general/BaseEntityModelFilter";
import store from "./../../../store";
import { getLanguage } from "./../../../utils/functions";
import { LANGUAGES } from "./../../../utils/constants";
import i18n from "./../../../i18n";

export default class DebtsFilter extends BaseEntityModelFilter {
  constructor() {
    super();
    this.setInitialValue();
  }
  setInitialValue() {
    this.setFilterInitialValue();
    this.language = getLanguage();
    switch (this.language) {
      case LANGUAGES.arEG:
        this.itemName = `{debtTitleAr} - ${i18n.t("type")}({debtTypeNameAr})`;
        break;
      case LANGUAGES.enUS:
        this.itemName = `{debtTitleEn} - ${i18n.t("type")}({debtTypeNameEn})`;
        break;
    }
    this.activationTypeTokens =
      store.getters.generalSetting.debts.activationTypeTokens ?? [];

    this.isFinshDebt = false;
    this.isNotFinshDebt = false;
    this.userInfoDataInclude = true;
    this.educationalJoiningApplicationInfoDataInclude = true;
    this.userToken = "";
    this.educationalCategoryTokens = [];
    this.systemComponentHierarchyToken = "";
    this.systemComponentToken = "";
    this.educationalCategoryToken = "";
    this.educationalGroupToken = "";
    this.educationalJoiningApplicationToken = "";
    this.schedulingStatusTypeToken = "";
    this.cancelTypeToken = "";
    this.debtTypeToken = "";
    this.debtMoneyFrom = "";
    this.debtMoneyTo = "";
    this.paidMoneyFrom = "";
    this.paidMoneyTo = "";
    this.remainderMoneyFrom = "";
    this.remainderMoneyTo = "";
    this.finishPercentageFrom = "";
    this.finishPercentageTo = "";
  }
  fillData(data) {
    if (data) {
      this.fillFilterData(data);
      this.isFinshDebt = data.isFinshDebt ?? false;
      this.isNotFinshDebt = data.isNotFinshDebt ?? false;
      this.userInfoDataInclude = data.userInfoDataInclude ?? true;
      this.educationalJoiningApplicationInfoDataInclude =
        data.educationalJoiningApplicationInfoDataInclude ?? true;
      this.userToken = data.userToken ?? "";
      this.educationalCategoryTokens = data.educationalCategoryTokens ?? [];
      this.educationalGroupToken = data.educationalGroupToken ?? "";
      this.educationalJoiningApplicationToken =
        data.educationalJoiningApplicationToken ?? "";
      this.schedulingStatusTypeToken = data.schedulingStatusTypeToken ?? "";
      this.cancelTypeToken = data.cancelTypeToken ?? "";
      this.debtTypeToken = data.debtTypeToken ?? "";
      this.debtMoneyFrom = data.debtMoneyFrom ?? "";
      this.debtMoneyTo = data.debtMoneyTo ?? "";
      this.paidMoneyFrom = data.paidMoneyFrom ?? "";
      this.paidMoneyTo = data.paidMoneyTo ?? "";
      this.remainderMoneyFrom = data.remainderMoneyFrom ?? "";
      this.remainderMoneyTo = data.remainderMoneyTo ?? "";
      this.finishPercentageFrom = data.finishPercentageFrom ?? "";
      this.finishPercentageTo = data.finishPercentageTo ?? "";
    } else {
      this.setInitialValue();
    }
  }
}
