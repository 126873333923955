import BaseEntityModelFilter from "./../../general/BaseEntityModelFilter";
import store from "./../../../store";

export default class AccountTransactionsFilter extends BaseEntityModelFilter {
  constructor() {
    super();
    this.setInitialValue();
  }
  setInitialValue() {
    this.setFilterInitialValue();
    this.accountTransactionTypeToken = "";
    this.accountToken = "";
    this.paymentMethodToken = "";
    this.sendTo = "";
    this.token = "";

    this.installmentPaymentToken = "";
    this.revenuesTypeToken = "";
    this.revenuesToken = "";
    this.expensesTypeToken = "";
    this.expensesToken = "";

    this.accountInfoDataInclude = true;
    this.expensesInfoDataInclude = true;
    this.revenuesInfoDataInclude = true;
    this.paymentMethodInfoDataInclude = true;
    try {
      this.activationTypeTokens =
        store.getters.generalSetting.accountTransactions.activationTypeTokens;
    } catch (error) {
      this.activationTypeTokens = [];
    }
  }
  fillData(data) {
    if (data) {
      this.accountTransactionTypeToken = data.accountTransactionTypeToken ?? "";
      this.accountToken = data.accountToken ?? "";
      this.paymentMethodToken = data.paymentMethodToken ?? "";
      this.sendTo = data.sendTo ?? "";
      this.token = data.token ?? "";
      this.installmentPaymentToken = data.installmentPaymentToken ?? "";
      this.revenuesTypeToken = data.revenuesTypeToken ?? "";
      this.revenuesToken = data.revenuesToken ?? "";
      this.expensesTypeToken = data.expensesTypeToken ?? "";
      this.expensesToken = data.expensesToken ?? "";
      this.accountInfoDataInclude = data.accountInfoDataInclude ?? true;
      this.expensesInfoDataInclude = data.expensesInfoDataInclude ?? true;
      this.revenuesInfoDataInclude = data.revenuesInfoDataInclude ?? true;
      this.paymentMethodInfoDataInclude =
        data.paymentMethodInfoDataInclude ?? true;
      this.activationTypeTokens = data.activationTypeTokens ?? [];
      this.fillFilterData(data);
    } else {
      this.setInitialValue();
    }
  }
}
