import BaseEntityModel from "./../../general/BaseEntityModel";
import defaultImg from "@/assets/images/accounts.svg";
import UserInfoData from "./../../general/UserCreatedData";

export default class Debt extends BaseEntityModel {
  icon = defaultImg;
  defaultImg = defaultImg;
  constructor() {
    super();
    this.setInitialValue();
  }
  setInitialValue() {
    this.setInitialValueBaseEntityModel();
    this.debtToken = "";
    this.debtOfModuleToken = ""; //debt on token
    this.debtTitleCurrent = "";
    this.debtTypeToken = "";
    this.debtTypeNameCurrent = "";
    this.schedulingData = null;
    this.debtMoneyStatistics = null;
    this.cancelationData = null;
    this.userToken = "";
    this.userInfoData = new UserInfoData();
  }
  fillData(data) {
    if (data) {
      this.fillDataBaseEntityModel(data);
      this.debtToken = data.debtToken ?? "";
      this.debtOfModuleToken = data.debtOfModuleToken ?? "";
      this.debtTitleCurrent = data.debtTitleCurrent ?? "";
      this.debtTypeToken = data.debtTypeToken ?? "";
      this.debtTypeNameCurrent = data.debtTypeNameCurrent ?? "";
      this.schedulingData = data.schedulingData ?? null;
      this.debtMoneyStatistics = data.debtMoneyStatistics ?? null;
      this.cancelationData = data.cancelationData ?? null;
      this.userToken = data.userToken ?? "";
      this.userInfoData.fillDataUserCreatedData(data.userInfoData);
    } else {
      this.setInitialValue();
    }
  }
}
